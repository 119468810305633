import { BootstrapData } from '../types'
import { AppSpecData } from '@wix/thunderbolt-symbols'
import _ from 'lodash'
import { MasterPageId } from './constants'

export interface WixCodeViewerAppUtils {
	createWixCodeAppData(
		appData: AppSpecData
	): {
		userCodeMap: Array<{
			url: string
			displayName: string
			id: string
			scriptName: string
		}>
	}
	setStaticEventHandlers(): void
}

export default function({ bootstrapData }: { bootstrapData: BootstrapData }) {
	const { wixCodePageIds } = bootstrapData.wixCodeBootstrapData
	return {
		createWixCodeAppData() {
			return {
				userCodeMap: [MasterPageId, bootstrapData.currentPageId!]
					.filter((pageId) => wixCodePageIds[pageId])
					.map((pageId: string) => ({
						url: wixCodePageIds[pageId],
						displayName: 'HOME page', // TODO what is this crap?
						id: pageId,
						scriptName: `${pageId}.js`
					}))
			}
		},
		setStaticEventHandlers: _.noop
	}
}
