import { API } from '../utils/api'
import { ReserveTicketsResponseCorvid, SelectedTickets, TicketReservationCorvid } from '../types/types'
import { omit } from 'lodash'

export const reserveTickets = async (
	eventId: string,
	selectedTickets: SelectedTickets
): Promise<ReserveTicketsResponseCorvid> => {
	const result: wix.events.ticketing.CreateReservationResponse = await API.post(
		`/events/${eventId}/tickets/reservation`,
		{
			ticketQuantities: selectedTickets.map((selectedTicket) => ({
				ticketDefinitionId: selectedTicket.ticketId,
				quantity: selectedTicket.quantity,
			})),
		}
	)

	return {
		...omit(result, 'expires'),
		reservations: result.reservations.map(formatTicketReservation),
		expirationTime: new Date(result.expires),
	}
}

const formatTicketReservation = (reservation: wix.events.ticketing.TicketReservation): TicketReservationCorvid => ({
	...reservation,
	ticket: {
		...omit(reservation.ticket, 'id'),
		_id: reservation.ticket.id,
	},
})
