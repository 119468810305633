import {
	namespace,
	PaidPlansWixCodeSdkFactoryInitialState,
	PaidPlansWixCodeSdkHandlers,
	PaidPlansWixCodeSdkWixCodeApi,
} from '..'
import { PricingPlansApi } from '../api'
import { APP_DEF_ID } from '../constants'

import { PlatformUtils } from '@wix/thunderbolt-symbols'

/**
 * This is SDK Factory.
 * Expose your Corvid API
 */
export function PaidPlansSdkFactory(
	{ someKey }: PaidPlansWixCodeSdkFactoryInitialState,
	_handlers: PaidPlansWixCodeSdkHandlers,
	{ sessionServiceApi }: PlatformUtils
): { [namespace]: PaidPlansWixCodeSdkWixCodeApi } {
	const getInstanceHeader = () => sessionServiceApi.getInstance(APP_DEF_ID)
	const api = new PricingPlansApi(getInstanceHeader)

	return {
		[namespace]: {
			getCurrentMemberOrders: api.getCurrentMemberOrders,
			someKey,
		},
	}
}
