import { API_BASE_URL } from './constants'
import { PaidPlansError } from './paid-plans-error'
import { mapApiOrderToCorvid } from './orders-mapper'

export class PricingPlansApi {
	constructor(private getInstanceHeader: () => string) {}

	createOrder = (planId: string) => {
		const purchasePlanRequest = {
			planId,
			useWixPay: true,
		}

		return this.httpApi.post('/orders', purchasePlanRequest).then((body) => ({
			orderId: body.orderId,
			wixPayOrderId: body.wixPayOrderId,
		}))
	}

	cancelOrder = (orderId: string) => {
		const cancelOrderRequest = { orderId }
		return this.httpApi.post(`/orders/${orderId}/cancel`, cancelOrderRequest)
	}

	getCurrentMemberOrders = (limit = 50, offset = 0) => {
		return this.httpApi
			.get(`/orders/my-orders?limit=${limit}&offset=${offset}`)
			.then((response) => response.orders.map(mapApiOrderToCorvid))
	}

	private httpApi = {
		get: (url: string) => this.sendRequest(url, 'get'),
		post: (url: string, body: object) => this.sendRequest(url, 'post', body),
	}

	private sendRequest(url: string, method: 'get' | 'post', body?: object): Promise<any> {
		return fetch(`${API_BASE_URL}${url}`, {
			method,
			headers: {
				Authorization: this.getInstanceHeader(),
			},
			body: body ? JSON.stringify(body) : undefined,
		})
			.catch((e) => {
				throw new PaidPlansError(e.status, e.message)
			})
			.then((response) => {
				if (!response.ok) {
					return response
						.text()
						.then(tryParsingJsonMessage)
						.then((message) => {
							throw new PaidPlansError(response.status, message)
						})
				}
				return response.json()
			})
	}
}

function tryParsingJsonMessage(text: string) {
	try {
		return JSON.parse(text).message || text
	} catch (e) {
		return text
	}
}
