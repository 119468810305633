import { GenericAPI, GetHeadersCb } from '@wix/wix-events-commons-statics/dist/src/api'
import { EVENTS_API_PATH } from '../constants'

const errorMessage = (payload: any) => {
	if (payload.message) {
		return payload.message
	}
	if (payload.details && payload.details.error_key) {
		return payload.details.error_key
	}
	return ''
}

export class ApiError extends Error {
	status: number
	payload: any

	constructor(status: number, payload: any) {
		super(`Request failed ${status} ${errorMessage(payload)}`)
		this.status = status
		this.payload = payload
	}
}

const handleError = (err: { status: number; payload: any }) => {
	throw new ApiError(err.status, err.payload)
}

let genericApi: GenericAPI

export const createGenericApi = (getRequestHeaders: GetHeadersCb, url: URL) => {
	genericApi = new GenericAPI(`${url.protocol}//${url.hostname}${EVENTS_API_PATH}`, getRequestHeaders)
}

export const API = {
	get: (path: string) => genericApi.get(path).catch(handleError),
	post: (path: string, body?: any) => genericApi.post(path, body).catch(handleError),
	delete: (path: string, body?: any) => genericApi.delete(path, body).catch(handleError),
	put: (path: string, body?: any) => genericApi.put(path, body).catch(handleError),
	patch: (path: string, body?: any) => genericApi.patch(path, body).catch(handleError),
}
