import _ from 'lodash'
import { WixCodeBootstrapData } from '@wix/thunderbolt-symbols'

export function elementorySupportScriptUrlFor(wixCodeBootstrapData: WixCodeBootstrapData) {
	return `${wixCodeBootstrapData.wixCodePlatformBaseUrl}/wixCodeNamespacesAndElementorySupport.min.js`
}

export async function importAndInitElementorySupport({ importScripts, wixCodeBootstrapData, wixCodeInstance, viewMode, csrfToken, externalBaseUrl }: any) {
	// @ts-ignore
	if (!self.elementorySupport) {
		await importScripts(elementorySupportScriptUrlFor(wixCodeBootstrapData), 'wixCodeNamespacesAndElementorySupport')
	}

	const options = { headers: { 'X-XSRF-TOKEN': csrfToken } }
	const queryParameters = `?gridAppId=${wixCodeBootstrapData.wixCodeModel.appData.codeAppId}&instance=${wixCodeInstance}&viewMode=${viewMode}`

	// @ts-ignore
	self.elementorySupport.baseUrl = `${externalBaseUrl}/_api/wix-code-public-dispatcher/siteview`
	// @ts-ignore
	self.elementorySupport.queryParameters = queryParameters
	// @ts-ignore
	self.elementorySupport.options = _.assign({}, self.elementorySupport.options, options)
}
