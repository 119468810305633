// @ts-ignore
import { wix } from '@wix/wix-events-commons-statics/types/proto-generated'

export interface SelectedTicket {
	ticketId: string
	quantity: number
}

export type SelectedTickets = Array<SelectedTicket>

export interface EditorInput {
	name: string
	value: string
}

export type EditorInputs = Array<EditorInput>

export interface EditorControl {
	array: boolean
	label: string
	options: Array<string>
	maxLength: number
	additionalLabels: Array<{ name: string; label: string }>
	required: boolean
	controlType: wix.events.form.InputControlType
	name: string
	_id: string
}

export interface RegistrationForm {
	rsvpStatus?: wix.events.rsvp.RsvpStatus
	[key: string]: any
}

export interface LegacyFormData {
	rsvpStatusOptions: wix.events.RsvpCollectionConfig.RsvpStatusOptions
	rsvpFormInputs: Array<EditorControl>
	registrationStatus: wix.events.RegistrationStatus
	isTicketed: boolean
}

export interface FormData {
	formInputs: Array<EditorControl>
	rsvpStatusOptions?: wix.events.RsvpCollectionConfig.RsvpStatusOptions
	registrationStatus: wix.events.RegistrationStatus
	isTicketed: boolean
}

export enum RsvpStatus {
	YES = 'YES',
	NO = 'NO',
	WAITING = 'WAITING',
}

export enum RsvpStatusOptions {
	YES_ONLY = 'YES_ONLY',
	YES_AND_NO = 'YES_AND_NO',
}

export enum EventType {
	NA_EVENT_TYPE = 'NA_EVENT_TYPE',
	RSVP = 'RSVP',
	TICKETS = 'TICKETS',
	EXTERNAL = 'EXTERNAL',
}

export enum RegistrationStatus {
	NA_REGISTRATION_STATUS = 'NA_REGISTRATION_STATUS',
	CLOSED = 'CLOSED',
	CLOSED_MANUALLY = 'CLOSED_MANUALLY',
	OPEN_RSVP = 'OPEN_RSVP',
	OPEN_RSVP_WAITLIST = 'OPEN_RSVP_WAITLIST',
	OPEN_TICKETS = 'OPEN_TICKETS',
	OPEN_EXTERNAL = 'OPEN_EXTERNAL',
}

export enum InputControlType {
	INPUT = 'INPUT',
	TEXTAREA = 'TEXTAREA',
	DROPDOWN = 'DROPDOWN',
	RADIO = 'RADIO',
	CHECKBOX = 'CHECKBOX',
	NAME = 'NAME',
	GUEST_CONTROL = 'GUEST_CONTROL',
	ADDRESS_SHORT = 'ADDRESS_SHORT',
	ADDRESS_FULL = 'ADDRESS_FULL',
	DATE = 'DATE',
}

export interface ValidationResponse {
	valid: boolean
}

export interface RsvpCorvid extends Omit<wix.events.rsvp.Rsvp, 'created' | 'modified' | 'rsvpForm'> {
	createdDate: Date
	updatedDate: Date
	rsvpForm: FormResponseCorvid
}

export interface FormResponseCorvid {
	inputValues: Array<Omit<wix.events.InputValue, 'number'>>
}

export interface TicketDefinitionCorvid extends Omit<wix.events.ticketing.TicketDefinition, 'id'> {
	_id: string
}

export interface TicketReservationCorvid extends Omit<wix.events.ticketing.TicketReservation, 'ticket'> {
	ticket: TicketDefinitionCorvid
}

export interface ReserveTicketsResponseCorvid
	extends Omit<wix.events.ticketing.CreateReservationResponse, 'expire' | 'reservations'> {
	expirationTime: Date
	reservations: Array<TicketReservationCorvid>
}

export interface OrderCorvid
	extends Omit<
		wix.events.ticketing.Order,
		'created' | 'checkoutForm' | 'snapshotId' | 'method' | 'totalPrice' | 'ticketsQuantity'
	> {
	createdDate: Date
	checkoutForm: FormResponseCorvid
	paymentId: string
	paymentMethod: string
	price: wix.events.Money
	ticketQuantity: number
}

export interface CheckoutResponseCorvid extends Omit<wix.events.ticketing.CheckoutResponse, 'expires' | 'order'> {
	expirationTime: Date
	order: OrderCorvid
}

export interface UpdateOrderResponseCorvid {
	order: OrderCorvid
}
