import { API, ApiError } from '../utils/api'
import { removeNumberPropFromInputs } from '../utils/formatting'
import { findFormSubmissionErrorType } from '../helpers'
import { FormSubmissionError, getFormSubmissionErrorMessage } from '../custom-errors'
import { RsvpCorvid } from '../types/types'
import { omit } from 'lodash'

export const createRsvp = (
	eventId: string,
	form: wix.events.FormResponse,
	status: wix.events.rsvp.RsvpStatus
): Promise<RsvpCorvid> => {
	const url = `/events/${eventId}/rsvp`
	const body = {
		status,
		form,
		fields: {
			paths: ['status', 'rsvpForm'],
		},
	}
	return API.post(url, body)
		.then((response) => handleCreateRsvpSuccess(response))
		.catch((err) => handleCreateRsvpError(err))
}

export const handleCreateRsvpSuccess = (response: wix.events.rsvp.CreateRsvpResponse): RsvpCorvid => {
	const { rsvp } = response
	const { rsvpForm } = rsvp

	return {
		...omit(rsvp, ['created', 'modified']),
		createdDate: new Date(rsvp.created),
		updatedDate: new Date(rsvp.modified),
		rsvpForm: removeNumberPropFromInputs(rsvpForm),
	}
}

export const handleCreateRsvpError = (error: ApiError) => {
	const { payload } = error
	if (payload && payload.error_key) {
		const { error_key: errorKey } = payload
		const errorType = findFormSubmissionErrorType(errorKey)
		const errorMessage = getFormSubmissionErrorMessage(errorType, payload)
		throw new FormSubmissionError(errorMessage, errorType)
	}
	throw error
}
