import { commonBiLoggerFactory } from '@wix/thunderbolt-commons'
import { PublishMethods } from '@wix/web-bi-logger/dist/src/logger'
import { PlatformEnvData } from '@wix/thunderbolt-symbols/dist'

const MESSAGE_TYPE = 'REPORT_BI'
const MESSAGE_INTENT = 'WIX_CODE_SSR'

const createBiLoggerFactory = ({
	biData,
	endpoint,
	factoryCreator
}: {
	biData: PlatformEnvData['bi']
	endpoint?: string
	factoryCreator: typeof commonBiLoggerFactory['createBaseBiLoggerFactory']
}) => {
	const { msId: msid, visitorId, siteMemberId, viewerSessionId, requestId, initialTimestamp, dc, is_rollout, isCached, rolloutData, pageData, muteBi } = biData
	const biStore = { msid, visitorId, siteMemberId, viewerSessionId, requestId, initialTimestamp, dc, is_rollout, isCached, rolloutData, pageData, is_headless: false }

	return process.env.browser
		? factoryCreator({ biStore, muteBi, endpoint })
		: factoryCreator({
				muteBi,
				biStore,
				endpoint,
				publishMethod: PublishMethods.PostMessage
		  }).withTransformer({
				[PublishMethods.PostMessage]: (params) => ({
					type: MESSAGE_TYPE,
					intent: MESSAGE_INTENT,
					params
				})
		  })
}

/**
 * Base BI logger factory, should be used as a basis for any BI logger in the Viewer Platform
 *
 * - Initialized with base defaults.
 * - Any additional defaults should be added via specialized factories, like FedOps,
 *   and only after making sure the BI schema supports them.
 *
 * Please use #bi-logger-support for any questions
 */
const createBaseBiLoggerFactory = (biData: PlatformEnvData['bi'], endpoint?: string) => {
	return createBiLoggerFactory({ biData, endpoint, factoryCreator: commonBiLoggerFactory.createBaseBiLoggerFactory })
}

/**
 * BI logger factory for Viewer Platform FedOps
 *
 * - Initialized with base defaults + defaults which are supported by FedOps BI events
 *   https://bo.wix.com/bi-catalog-webapp/#/sources/72
 *
 * - Any additional defaults should be added only after making sure the BI schema supports them
 *
 * Please use #bi-logger-support for any questions
 */
const createBiLoggerFactoryForFedops = (biData: PlatformEnvData['bi']) => {
	return createBiLoggerFactory({ biData, factoryCreator: commonBiLoggerFactory.createBiLoggerFactoryForFedops })
}

export const platformBiLoggerFactory = {
	createBaseBiLoggerFactory,
	createBiLoggerFactoryForFedops
}
