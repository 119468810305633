import { API } from '../utils/api'
import { removeNumberPropFromInputs } from '../utils/formatting'
import { CheckoutResponseCorvid, OrderCorvid, RegistrationForm, UpdateOrderResponseCorvid } from '../types/types'
import { omit } from 'lodash'

export const checkout = async (
	eventId: string,
	reservationId: string,
	{ guest, couponCode }: { guest: RegistrationForm; couponCode: string }
): Promise<CheckoutResponseCorvid> => {
	const result: wix.events.ticketing.CheckoutResponse = await API.post(`/events/${eventId}/tickets/checkout`, {
		reservationId,
		guests: [{ form: { inputValues: guest } }],
		discount: couponCode ? { couponCode } : null,
	})

	return {
		...omit(result, 'expires'),
		expirationTime: new Date(result.expires),
		order: formatOrder(result.order),
	}
}

export const updateOrder = async (
	eventId: string,
	orderNumber: string,
	{ guest }: { guest: RegistrationForm }
): Promise<UpdateOrderResponseCorvid> => {
	const result: wix.events.ticketing.UpdateOrderResponse = await API.patch(
		`/events/${eventId}/tickets/checkout/${orderNumber}`,
		{ guests: [{ form: { inputValues: guest } }] }
	)
	return {
		order: formatOrder(result.order),
	}
}

export const verifyCoupon = (
	eventId: string,
	reservationId: string,
	couponCode: string
): Promise<wix.events.ticketing.GetInvoiceResponse> => {
	return API.post(`/events/${eventId}/tickets/reservation/${reservationId}/invoice`, {
		withDiscount: { couponCode },
	})
}

const formatOrder = (order: wix.events.ticketing.Order): OrderCorvid => ({
	...omit(order, ['created', 'snapshotId', 'method', 'ticketsQuantity', 'totalPrice']),
	paymentId: order.snapshotId,
	createdDate: new Date(order.created),
	checkoutForm: removeNumberPropFromInputs(order.checkoutForm),
	paymentMethod: order.method,
	ticketQuantity: order.ticketsQuantity,
	price: order.totalPrice,
})
