import { Connection } from '@wix/thunderbolt-symbols'

export default function({ compId, compType, role, connection, handlers }: { compId: string; compType: string; role: string; connection?: Connection; handlers: any }) {
	const global = false
	const type = `$w.${compType}`
	return {
		get id() {
			return role
		},
		get connectionConfig() {
			return connection?.config
		},
		get uniqueId() {
			return compId
		},
		get parent() {
			return null
		},
		get global() {
			return global
		},
		get type() {
			return type
		},
		scrollTo() {
			return new Promise((resolve) => handlers.scrollToComponent(compId, resolve))
		},
		toJSON() {
			return { id: role, type, global }
		}
	}
}
